<template>
    <NavBar />
    <SideBar />
    <div :class="['content', isSidebarOpen ? 'open' : 'closed', audio ? 'content-player-padding' : '']">
        <router-view />
    </div>
    <div :class="['player-small', isSidebarOpen ? 'open' : 'closed']">
        <AudioPlayerSmall />
    </div>
    <audio id="player" :src="player.currentAudio.file" crossorigin="anonymous"></audio>
</template>

<script>
import NavBar from './components/NavBar.vue'
import SideBar from './components/SideBar.vue'
import AudioPlayerSmall from './components/AudioPlayerSmall.vue'
import { getCookie } from './utils.js'

export default {
    name: 'App',
    components: {
        NavBar,
        SideBar,
        AudioPlayerSmall
    },
    data() {
        return {
            interval: null
        }
    },
    computed: {
        isSidebarOpen() {
            return this.$store.getters.layout.isSidebarOpen
        },
        user() {
            if (this.$store.getters.userData == null) {
                return null
            }

            return this.$store.getters.userData.user
        },
        player() {
            return this.$store.getters.player
        },
        audio() {
            return this.$store.getters.player.currentAudio.audio
        }
    },
    methods: {
        getTokensCookie() {
            const tokens = getCookie("tokens")

            return tokens
        },
        getUserDataCookie() {
            const userData = getCookie("user_data")
            
            return userData
        },
        getTagSaveCookie() {
            const tagSave = getCookie("tag_save")

            return tagSave
        },
    },
    async created() {
        const userData = this.getUserDataCookie()
        if (userData != null) {
            this.$store.commit("setUserData", userData)
        }

        const tagSave = this.getTagSaveCookie()
        if (tagSave != null) {
            this.$store.commit("setTagSave", tagSave)
        }
    }
}
</script>

<style scoped>
    .content-player-padding {
        padding-bottom: 130px;
    }

    @media screen and (max-width: 40rem) {
        .content-player-padding {
            padding-bottom: 160px;
        }
    }
</style>

<style>
    :root {
        --primary-background: #1E1E2F;
        --secondary-background: #262639;
        --tertiary-background: #313149;
        --primary-color: #A688FA;
        --secondary-color: #ADADCB;
        --primary-color-transparent: #A688FA33;
        --def-transition-duration: 0.4s;
        --color-green: #32CD32;
        --color-green-transparent: #32CD3233;
        --color-red: #FF004F;
        --color-red-transparent: #FF004F33;
        --color-orange: #F59460;
    }    

    body {
        color: #FFFFFF !important;
        background-color: var(--primary-background) !important;
    }

    a {
        color: var(--primary-color) !important;
        text-decoration: none !important;
        transition: color var(--def-transition-duration) ease-in-out;
    }

    .color-primary {
        color: var(--primary-color);
    }

    .color-secondary {
        color: var(--secondary-color);
    }

    .color-green {
        color: var(--color-green);
    }

    .color-red {
        color: var(--color-red);
    }

    .color-orange {
        color: var(--color-orange);
    }

    a:hover {
        color: #957ae1 !important;
    }

    #app {
        font-family: "Poppins", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .content {
        margin-left: 5em;
        margin-right: 5em;
        margin-top: 2em;
        padding-left: 220px;
        overflow: hidden;
    }

    .player-small {
        overflow: hidden;
    }

    @media screen and (max-width: 40rem) {
        .content {
            margin-left: 0.5em;
            margin-right: 0.5em;
        }

        .closed {
            padding-left: 0;
        }

        .open {
            padding-left: 0;
        }
    }

    .input-group {
        margin-bottom: 10px;
    }

    .input-group-prepend {
        display: flex;        
    }

    .input-group .form-control:last-child {
        border-left: 0 none;
    }

    .input-group > .input-group-prepend > .input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #46424F !important;
        color: white;
    }

    .card .input-group-prepend .input-group-text {
        border-right: none;
        background-color: transparent;
    }

    .form-control:disabled {
        background-color: transparent !important;
    }

    .form-select-pink {
        background-color: #27293d !important;
        color: rgba(255, 255, 255, 0.6) !important;
        border-color: var(--primary-color) !important;
        border-width: 2px !important;
        font-weight: 600 !important;
    }

        .form-select-pink:focus {
            border-color: #ba54f5 !important;
            box-shadow: 0 0 0 0.25rem rgba(186, 84, 245, 0.3) !important;
        }

    .form-select-green {
        background-color: #27293d !important;
        color: rgba(255, 255, 255, 0.6) !important;
        border-color: #32CD32 !important;
        border-width: 2px !important;
        font-weight: 600 !important;
    }

        .form-select-green:focus {
            border-color: #32CD32 !important;
            box-shadow: 0 0 0 0.25rem rgba(50, 205, 50, 0.3) !important;
        }

    .card-body {
        padding: 0 !important;
    }

    .card-dark {
        width: 100%;
        background: var(--secondary-background) !important;
        color: var(--secondary-color);
        box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
        --bs-card-border-width: 0 !important;
        --bs-card-cap-bg: transparent !important;
        border-radius: 30px !important;
        padding: 40px !important;
    }

    @media screen and (max-width: 40rem) {
        .card-dark {
            padding: 24px !important;
        }
    }

    .card-dark .form-control {
        border-color: #46424F;
        border-radius: 0.5rem;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        background-color: transparent;
        color: var(--secondary-color) !important;
    }

            .card-dark .form-control:focus {
                border-color: var(--primary-color);
                background-color: transparent;
                box-shadow: none;
                color: rgba(255, 255, 255, 0.6) !important;
            }

    .btn {
        transition: all var(--def-transition-duration) ease !important;
    }

    .btn-sm {
        padding: 6px 12px 6px 12px !important;
    }

    .btn-primary:not(:disabled) {
        background-color: var(--primary-color) !important;
        border: 1px transparent solid !important;
        border-radius: 50px;
        box-shadow: none;
        color: var(--primary-background) !important;
        min-width: 5rem;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 16px 24px 16px 24px;
    }

        .btn-primary:not(:disabled):hover {
            background-color: var(--primary-color);
            letter-spacing: 2px;
        }

    .btn-primary:disabled {
        background-color: var(--primary-color-transparent) !important;
        border: 1px transparent solid !important;
        border-radius: 50px;
        box-shadow: none;
        color: var(--primary-background) !important;
        min-width: 5rem;
        font-size: 16px;
        line-height: 24px;
        padding: 16px 24px 16px 24px;
    }

    .btn-secondary {
        background-color: var(--secondary-background) !important;
        border-radius: 50px !important;
    }

    .btn-secondary:not(:disabled) {
        border: 1px var(--secondary-background) solid !important;
        color: var(--primary-color);
    }

    .btn-secondary.color-white:not(:disabled) {
        color: white;
    }

        .btn-secondary:not(:disabled):hover {
            border: 1px var(--primary-color) solid !important;
            background-color: #A688FA33 !important;
            color: var(--primary-color) !important;
        }

        .btn-secondary.color-white:not(:disabled):hover {
            color: white !important;
        }

    .btn-tertiary:not(:disabled) {
        background: transparent;
        background-color: transparent;
        border: 2px solid gray;
        box-shadow: none;
        color: #ffffff !important;
        min-width: 4rem;
    }

        .btn-tertiary:not(:disabled):hover {
            border: 2px solid var(--primary-color);
        }

        .btn-tertiary:not(:disabled):active {
            border: 2px solid var(--primary-color) !important;
            background-color: rgba(255, 255, 255, 0.15) !important;
        }


    .card-dark .form-check-input {
        background-color: transparent !important;
        border-color: var(--primary-color) !important;
    }

    .form-check-input:checked {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }

    .form-check-input:focus {
        box-shadow: none !important;
    }

    .input-group:focus-within .form-control:focus,
    .input-group:focus-within .input-group-text {
        border-color: var(--primary-color) !important;
        box-shadow: none !important;
    }

    .pull-left {
        float: left;
    }

    .pull-right {
        float: right;
    }

    .background-pink {
        background-color: #ba54f5;
    }

    .background-green {
        background-color: #00f2c3;
    }

    .progress-bar {
        background: var(--primary-color) !important;
    }

    .dot-flashing {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
    }

        .dot-flashing::before, .dot-flashing::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
        }

        .dot-flashing::before {
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dot-flashing 1s infinite alternate;
            animation-delay: 0s;
        }

        .dot-flashing::after {
            left: 15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #9880ff;
            color: #9880ff;
            animation: dot-flashing 1s infinite alternate;
            animation-delay: 1s;
        }

    @keyframes dot-flashing {
        0% {
            background-color: #9880ff;
        }

        50%, 100% {
            background-color: rgba(152, 128, 255, 0.2);
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .color-white {
        color: white;
    }

    .dialog-btn-primary {
        background-color: var(--primary-color);
        border: none;
        border-radius: 50px;
        box-shadow: none;
        color: var(--primary-background);
        min-width: 5rem;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px 8px 16px;
        margin: 4px;
        transition: all var(--def-transition-duration) ease;
        min-width: 110px;
    }

        .dialog-btn-primary:hover {
            letter-spacing: 1px;
        }

    .dialog-btn-secondary {
        background-color: transparent;
        border: 1px var(--primary-color) solid !important;
        border: none;
        border-radius: 50px;
        box-shadow: none;
        color: var(--primary-color);
        min-width: 5rem;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px 8px 16px;
        margin: 4px;
        transition: all var(--def-transition-duration) ease;
        width: 110px;
    }

        .dialog-btn-secondary:hover {
            letter-spacing: 1px;
        }

        .dialog-btn-secondary.color-red {
            background-color: var(--color-red-transparent);
            border: 1px var(--color-red) solid !important;
            color: var(--color-red);
        }

        .dialog-btn-secondary.color-green {
            background-color: var(--color-green-transparent);
            border: 1px var(--color-green) solid !important;
            color: var(--secondary-color);
        }

    .pt-8 {
        padding-top: 8px;
    }

    .pt-12 {
        padding-top: 12px;
    }

    .pt-16 {
        padding-top: 16px;
    }

    .pt-24 {
        padding-top: 24px;
    }

    .pb-8 {
        padding-bottom: 8px;
    }

    .pb-12 {
        padding-bottom: 12px;
    }

    .pb-16 {
        padding-bottom: 16px;
    }

    .pb-24 {
        padding-bottom: 24px;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: var(--primary-background);
    }

    ::-webkit-scrollbar-thumb {
        background: var(--primary-color);
    }
</style>

import { createWebHistory, createRouter } from "vue-router";
import SearchPage from './../components/SearchPage.vue'
import SearchTagPage from './../components/SearchTagPage.vue'
import LoginPage from './../components/LoginPage.vue'
import RegisterPage from './../components/RegisterPage.vue'
import ProfilePage from './../components/ProfilePage.vue'
import SettingsPage from './../components/SettingsPage.vue'
import ConfirmPage from './../components/ConfirmPage.vue'
import UploadPage from './../components/UploadPage.vue'
import AudioPage from './../components/AudioPage.vue'
import EditAudioPage from './../components/EditAudioPage.vue'
import CreatePasswordResetPage from './../components/CreatePasswordResetPage.vue'
import ConfirmPasswordResetPage from './../components/ConfirmPasswordResetPage.vue'
import PeoplePage from '../components/PeoplePage.vue'
import FollowsPage from '../components/FollowsPage.vue'
import CategoriesPage from '../components/CategoriesPage.vue'
import AdministrationPage from '../components/AdministrationPage.vue'
import AnalyticsPage from '../components/AnalyticPage.vue'
import AudioAnalyticsPage from '../components/AudioAnalyticsPage.vue'
import TermsConditionsPage from '../components/TermsConditionsPage.vue'
import ImportPage from '../components/ImportPage.vue'
import RewardsPage from '../components/RewardsPage.vue'
import MonetizationAdminPage from '../components/MonetizationAdminPage.vue'
import MonetizationPage from '../components/MonetizationPage.vue'
import InvoicesAdminPage from '../components/InvoicesAdminPage.vue'
import InvoiceTemplatePage from '../components/InvoiceTemplatePage.vue'
import InvoiceDetailPage from '../components/InvoiceDetailPage.vue'

const routes = [
    {
        path: "/",
        name: "Search",
        component: SearchPage,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/login/:thirdParty",
        name: "Login ",
        component: LoginPage,
    },
    {
        path: "/register",
        name: "Register",
        component: RegisterPage,
    },
    {
        path: "/register/:thirdParty",
        name: "Register ",
        component: RegisterPage,
    },
    {
        path: "/:handle",
        name: "Profile",
        component: ProfilePage,
    },
    {
        path: "/settings",
        name: "Settings",
        component: SettingsPage,
    },
    {
        path: "/settings/connect/:thirdParty",
        name: "Settings ",
        component: SettingsPage,
    },
    {
        path: "/confirm/:id",
        name: "Confirm",
        component: ConfirmPage,
    },
    {
        path: "/upload",
        name: "Upload",
        component: UploadPage,
    },
    {
        path: "/audio/:id",
        name: "Audio",
        component: AudioPage,
    },
    {
        path: "/audio/:id/edit",
        name: "Edit audio",
        component: EditAudioPage
    },
    {
        path: "/password-reset",
        name: "Reset password",
        component: CreatePasswordResetPage
    },
    {
        path: "/password-reset/:id",
        name: "Reset password ",
        component: ConfirmPasswordResetPage
    },
    {
        path: "/follows",
        name: "Follows",
        component: FollowsPage
    },
    {
        path: "/people",
        name: "People",
        component: PeoplePage
    },
    {
        path: "/categories",
        name: "Categories",
        component: CategoriesPage
    },
    {
        path: "/categories/:tagId",
        name: "Category",
        component: SearchTagPage
    },
    {
        path: "/administration",
        name: "Administration",
        component: AdministrationPage
    },
    {
        path: "/analytics",
        name: "Analytics",
        component: AnalyticsPage
    },
    {
        path: "/analytics/user/:userId",
        name: "Analytics ",
        component: AnalyticsPage
    },
    {
        path: "/analytics/audio/:id",
        name: "Audio analytics",
        component: AudioAnalyticsPage
    },
    {
        path: "/terms-conditions",
        name: "Terms and conditions",
        component: TermsConditionsPage
    },
    {
        path: "/import",
        name: "Import",
        component: ImportPage
    },
    {
        path: "/rewards",
        name: "Rewards",
        component: RewardsPage
    },
    {
        path: "/monetization-admin",
        name: "Monetization Admin",
        component: MonetizationAdminPage
    },
    {
        path: "/monetization",
        name: "Monetization",
        component: MonetizationPage
    },
    {
        path: "/monetization/invoice/:id",
        name: "Invoice",
        component: InvoiceDetailPage
    },
    {
        path: "/monetization-admin/invoices",
        name: "Invoice Admin",
        component: InvoicesAdminPage
    },
    {
        path: "/monetization-admin/invoice-draft/:id",
        name: "Invoice Draft",
        component: InvoiceTemplatePage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
<template>
    <div v-if="!profile && isLoaded">
        <h3 class="text-center">{{ error }}</h3>
    </div>
    <div v-if="profile">
        <ProfileCard v-if="profile" class="header" :profile="profile" ref="profileCard" />
    </div>
    <div v-if="!isLoaded">
        <div class="card card-dark blank-profile-card">

        </div>
    </div>
    <div v-if="profile || !isLoaded" class="view-more-profile">
        View More Profile
    </div>
    <div class="audio-table" ref="scrollElement">
        <AudioCard v-if="!audios && (profile || !isLoaded)"></AudioCard>
        <AudioCard v-if="audios" v-for="audio in audios" :audio="audio" :showMoreOptions="isLoggedUsersProfile" @deleted="audioDeleted"></AudioCard>
        <NothingMoreCard v-show="loadedAllTime" :scroll="scrollUp"></NothingMoreCard>
    </div>
    <div v-show="isLoadingMore" class="mt-4 center-row">
        <div class="dot-flashing"></div>
    </div>
    <div v-show="audios && !isLoadingMore" class="mt-3 center-row">
        <button class="btn btn-secondary" @click="loadMore()">
            <fai :icon="['fas', 'plus']"></fai>
            Load more
        </button>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import ProfileCard from './ProfileCard.vue'
    import AudioCard from './AudioCard.vue'
    import NothingMoreCard from './NothingMoreCard.vue'
    import { setMetadata } from '../utils.js'

    export default {
        name: 'ProfilePage',
        components: {
            ProfileCard,
            AudioCard,
            NothingMoreCard
        },
        data() {
            return {
                isLoaded: false,
                error: null,
                profile: null,
                audios: null,
                loadCount: 15,
                isLoadingMore: false,
                loadedAllTime: null,
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user
                }
                return null
            },
            isLoggedUsersProfile() {
                if (this.$store.getters.userData == null) {
                    return false
                }

                return this.profile != null && this.$store.getters.userData.userProfileId == this.profile.id
            },
            follows() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.follows
                }
                return null
            },
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            }
        },
        methods: {
            displayError(response) {
                if (!response.error) {
                    this.error = "Something went wrong when loading the profile."
                } else {
                    this.error = response.error
                }
            },
            audioDeleted(id) {
                this.audios = this.audios.filter(x => x.id != id)
            },
            async handleScroll(e) {
                let element = this.$refs.scrollElement
                if (!element) {
                    return
                }
                if (element.getBoundingClientRect().bottom < window.innerHeight) {
                    await this.loadMore()
                }
            },
            async loadMore() {
                const currentTime = new Date()
                if (!this.isLoadingMore && Math.abs(currentTime - this.loadedAllTime) > 5000) {
                    this.isLoadingMore = true

                    const audioResponse = await requests.UserContent.Audio.GetAudiosByProfileIdSkipTake(this.profile.id, this.audios.length, this.loadCount)

                    if (audioResponse.isSuccess) {
                        this.audios.push(...audioResponse.audios)

                        if (audioResponse.audios.length < this.loadCount) {
                            this.loadedAllTime = new Date()
                        }
                    }

                    this.isLoadingMore = false
                }
            },
            scrollUp() {
                this.$refs.profileCard.scrollIntoView({ behavior: "smooth" })
            },
        },     
        async mounted() {
            requests.initialize(this.$store)

            const response = await requests.UserContent.UserProfiles.GetUserProfileByHandle(this.$route.params.handle)

            if (response.isSuccess) {
                this.profile = response.userProfile

                setMetadata(this.profile.name + " | AudioLove", this.profile.description, this.profile.name)
            } else {
                this.displayError(response)
                this.isLoaded = true
                return
            }

            this.isLoaded = true

            const audioResponse = await requests.UserContent.Audio.GetAudiosByProfileIdSkipTake(this.profile.id, 0, this.loadCount * 2)

            if (audioResponse.isSuccess) {
                this.audios = audioResponse.audios

                if (audioResponse.audios.length < this.loadCount * 2) {
                    this.loadedAllTime = new Date()
                }
            }

            window.addEventListener("scroll", this.handleScroll)
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.handleScroll)
        }
    }
</script>

<style scoped>

    .profile-row {
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    .name {
        font-size: 2rem;
        margin-right: 0.5rem;
    }

    .lock-icon {
        padding: 0.1rem 0.35rem;
        background-color: #e14eca;
        color: white;
        border-radius: 1rem;
    }

    .followers-left {
        padding: 0.1rem 0.6rem;
        margin-left: 0.5rem;
        border: 1px solid #e14eca;
        background-color: #e14eca;
        color: white;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    .followers-right {
        padding: 0.1rem 0.6rem;
        border: 1px solid #e14eca;
        color: white;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        cursor: pointer;
    }

    .followers-round {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .upload-card {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        background-color: #ba54f5;
        color: white !important;
        padding: 1rem;
        cursor: pointer;
        transition: background-color ease-in-out 0.2s;
    }

        .upload-card:hover {
            background-color: #e14eca;
        }

    .audio-table {
        display: flex;
        flex-flow: column;
        row-gap: 1rem;
    }

        .audio-table div {
            cursor: pointer;
        }

    @media only screen and (min-width: 1550px) {
        .audio-table {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            column-gap: 1%;
        }

            .audio-table div {
                flex-basis: 49%;
            }
    }

    .center-row {
        display: flex;
        justify-content: center;
    }

    .more-options {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: flex;
        flex-flow: row;
        column-gap: 0.5rem;
    }

    .view-more-profile {
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
        padding-top: 48px;
        padding-bottom: 24px;
    }

    .blank-profile-card {
        min-height: 240px;
    }
</style>
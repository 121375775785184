export function setMetadata(title, description, author, keywords) {
    document.title = title ? title : "AudioLove - Erotic Audio Haven"
    document.querySelector('meta[name="description"]').setAttribute("content", description ? description : "")
    document.querySelector('meta[name="author"]').setAttribute("content", author ? author : "")
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords ? keywords : "")
}

export function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
}

export function formatDateNumeric(date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
}

export function formatDateTimeNumeric(date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
}

export function formatPrice(price, currency) {
    if (!currency) {
        currency = "USD"
    }

    return (price / 100) + " " + currency
}

export function dateToTimeAgoString(date, longFormat) {
    let dateObject = new Date(date)

    const now = new Date()
    const secondsPast = (now.getTime() - dateObject.getTime()) / 1000

    if (secondsPast < 60) { // less than a minute
        return Math.round(secondsPast) + (longFormat ? ' seconds ago' : 's ago')
    } else if (secondsPast < 3600) { // less than an hour
        return Math.round(secondsPast / 60) + (longFormat ? ' minutes ago' : 'm ago')
    } else if (secondsPast < 86400) { // less than a day
        return Math.round(secondsPast / 3600) + (longFormat ? ' hours ago' : 'h ago')
    } else if (secondsPast < 604800) { // less than a week
        return Math.round(secondsPast / 86400) + (longFormat ? ' days ago' : 'd ago')
    } else if (secondsPast < 2592000) { // less than a month
        return Math.round(secondsPast / 604800) + (longFormat ? ' weeks ago' : 'w ago')
    } else if (secondsPast < 31536000) { // less than a year
        return Math.round(secondsPast / 2592000) + (longFormat ? ' months ago' : 'mo ago')
    } else { // a year or more
        return Math.round(secondsPast / 31536000) + (longFormat ? ' years ago' : 'y ago')
    }
}

export function formatHourToTime(hour) {
    const offset = (new Date()).getTimezoneOffset()

    const now = new Date()
    now.setHours(hour, 0, 0)
    const date = (new Date((now).getTime() - (offset * 60 * 1000)))

    return date.getHours() + ":00"
}

export function setCookie(name, data) {
    const expiration = new Date()
    expiration.setTime(expiration.getTime() + (91 * 24 * 60 * 60 * 1000))

    document.cookie = name + "=" + JSON.stringify(data) + "; Path=/; Expires=" + expiration + ";"
}

export function getCookie(name) {
    const data = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

    const expiration = new Date()
    expiration.setTime(expiration.getTime() + (91 * 24 * 60 * 60 * 1000))

    return data == '' ? null : JSON.parse(data)
}

export function removeCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export function getNowDate() {
    return (new Date()).toISOString().split('T')[0]
}

export function getTomorrowDate() {
    let date = new Date(getNowDate())
    date.setDate(date.getDate() + 1)
    return date.toISOString().split('T')[0]
}

export function getNowDateLocal() {
    const offset = (new Date()).getTimezoneOffset()

    return (new Date((new Date()).getTime() - (offset * 60 * 1000))).toISOString().split('T')[0]
}

export function secondsToTimeLabel(seconds) {
    const units = [
        { seconds: 86400 }, // days
        { seconds: 3600 },  // hours
        { seconds: 60 },    // minutes
        { seconds: 1 }      // seconds
    ];

    let result = units.map(unit => {
        const value = Math.floor(seconds / unit.seconds)
        seconds %= unit.seconds
        return value !== 0 || unit.seconds === 1 ? String(value).padStart(2, '0') : ''
    }).filter(Boolean).join(':').replace(/^0+/, '')

    if (result.indexOf(':') === -1) {
        result = '0:' + result;
    }

    return result
}

export function getTomorrowDateLocal() {
    let date = new Date(getNowDate())
    date.setDate(date.getDate() + 1)
    return date.toISOString().split('T')[0]
}

export function chartDataFromChartPoints(chartPoints, color, label, fill) {
    const labels = []
    const ids = []
    const data = []

    for (const chartPoint of chartPoints) {
        labels.push(chartPoint.label)
        ids.push(chartPoint.label)
        data.push(chartPoint.value)
    }

    const chartData = {
        labels: labels,
        ids: ids,
        datasets: [
            {
                label: label,
                data: data,
                borderColor: color,
                backgroundColor: color,
                fill: fill,
                pointRadius: 0
            }
        ]
    }

    return chartData
}

export async function lookupUserProfilesByLabels(requests, labels) {
    const profilesResult = await requests.UserContent.UserProfiles.LookupByUserIds(labels)
    if (!profilesResult.isSuccess) {
        return { labels: labels, userProfiles: [] }
    }

    const profilesByIdsDict = { }
    for (const profile of profilesResult.userProfiles) {
        profilesByIdsDict[profile.userId] = profile
    }

    const newLabels = []
    for (const label of labels) {
        if (!profilesByIdsDict[label]) {
            newLabels.push(label)
            continue;
        }

        newLabels.push(profilesByIdsDict[label].name.length <= 20
            ? profilesByIdsDict[label].name
            : profilesByIdsDict[label].name.substring(0, 17) + "...")
    }

    return { labels: newLabels, userProfiles: profilesResult.userProfiles }
}

export async function lookupAudiosByLabels(requests, labels) {
    const audioResult = await requests.UserContent.Audio.LookupByIds(labels)
    if (!audioResult.isSuccess) {
        return { labels: labels, audios: [] }
    }
    const audiosByIdsDict = {}
    for (const audio of audioResult.audios) {
        audiosByIdsDict[audio.id] = audio
    }
    const newLabels = []
    for (const label of labels) {
        if (!audiosByIdsDict[label]) {
            newLabels.push(label)
            continue;
        }

        const validLabel = audiosByIdsDict[label].name.length <= 20
            ? audiosByIdsDict[label].name
            : audiosByIdsDict[label].name.substring(0, 17) + "..."

        newLabels.push(audiosByIdsDict[label]
            ? validLabel
            : label)
    }

    return { labels: newLabels, audios: audioResult.audios }
}

export async function lookupTagsByLabels(requests, labels) {
    const tagResult = await requests.UserContent.Tag.LookupByIds(labels)
    if (!tagResult.isSuccess) {
        return { labels: labels, tags: [] }
    }
    const tagsByIdsDict = {}
    for (const tag of tagResult.tags) {
        tagsByIdsDict[tag.id] = tag
    }
    const newLabels = []
    for (const label of labels) {
        if (!tagsByIdsDict[label]) {
            newLabels.push(label)
            continue;
        }

        const validLabel = tagsByIdsDict[label].name.length <= 20
            ? tagsByIdsDict[label].name
            : tagsByIdsDict[label].name.substring(0, 17) + "..."

        newLabels.push(tagsByIdsDict[label]
            ? validLabel
            : label)
    }

    return { labels: newLabels, tags: tagResult.tags }
}
<template>
    <div class="box" :class="[status == 'SELECTED' ? 'selected' : '', redirect ? 'redirectable' : '']" :title="tag.description" @click="redirectToCategory()">
        <span class="text">{{ tag.name }}</span>
    </div>
</template>

<script>
    export default {
        name: 'TagCard',
        props: {
            tag: {
                default: null,
                type: Object
            },
            status: {
                default: "DEFAULT",
                type: String
            },
            redirect: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            redirectToCategory() {
                if (!this.redirect) {
                    return
                }

                this.$router.push({ name: 'Category', params: { tagId: this.tag.id } })
            }
        }
    }
</script>

<style scoped>
    .box {
        border-radius: 6px;
        border: 1px solid #46424F;
        padding: 5px 10px 5px 10px;
    }

    .text {
        font-size: 16px;
        line-height: 24px;
        color: #ADADCB;
    }

    .selected {
        background-color: var(--color-green-transparent);
        border-color: var(--color-green);
        color: #FFFFFF;
    }

    .redirectable {
        cursor: pointer;
    }
</style>
<template>
    <h4 class="title">Listens By Time</h4>
    <div class="card card-dark">
        <div class="card-body">
            <label class="sum">
                <fai :icon="['fas', 'clock']"></fai>
                {{ totalListens }} listens during this time
            </label>
            <div class="time-select">
                <select v-model="selectedTime" class="form-select form-select-pink time-select" aria-label="Time selection" @change="loadData()">
                    <option value="1">Last week</option>
                    <option value="2">Last month</option>
                    <option value="3">Last three months</option>
                    <option value="4">Last six months</option>
                    <option value="5">Last year</option>
                    <option value="6">All time</option>
                </select>
            </div>
            <div class="chart-container">
                <div v-if="!chartData" class="dot-flashing"></div>
                <div v-else-if="notEnoughData">Not enough data. Try again tomorrow.</div>
                <canvas v-show="chartData && !notEnoughData" ref="chart" />
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import { chartDataFromChartPoints, getTomorrowDate } from '../utils.js'
    import Chart from 'chart.js/auto'

    export default {
        name: 'TotalAudioListensByTimeChart',
        props: {
            isAdminChart: {
                default: false,
                type: Boolean
            },
            audioId: {
                default: null,
                type: String
            },
            userId: {
                default: null,
                type: String
            }
        },
        data() {
            return {
                selectedTime: "2",
                chartData: null,
                notEnoughData: false,
                totalListens: 0
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData == null) {
                    return null
                }

                return this.$store.getters.userData.user
            }
        },
        methods: {
            async loadData() {
                const tomorrowDate = getTomorrowDate()
                const fromDate = this.getFromDate()

                const result = await requests.Statistics.Charts.TotalAudioListensByTime(
                    this.audioId,
                    this.isAdminChart
                        ? null
                        : this.userId,
                    fromDate, tomorrowDate)

                if (!result.isSuccess) {
                    return
                }

                this.totalListens = result.chartPoints.reduce((accumulator, currentData) => {
                    return accumulator + currentData.value;
                }, 0);
                this.chartData = chartDataFromChartPoints(result.chartPoints, "#A688FA")
                this.notEnoughData = this.chartData.labels.length < 3

                if (!this.$refs.chart) {
                    return
                }

                if (this.chart) {
                    this.chart.data = this.chartData

                    this.chart.update()
                    return
                }

                const ctx = this.$refs.chart.getContext('2d');

                const config = {
                    type: 'line',
                    data: this.chartData,
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        scales: {
                            y: {
                                min: 0,
                            }
                        }
                    },
                }

                this.chart = new Chart(ctx, config);
            },
            getFromDate() {
                const offset = (new Date()).getTimezoneOffset()

                if (this.selectedTime == "1") {
                    const weekBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    weekBeforeDate.setDate(weekBeforeDate.getDate() - 7)
                    return weekBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "2") {
                    const monthBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    monthBeforeDate.setDate(monthBeforeDate.getDate() - 31)
                    return monthBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "3") {
                    const threeMonthsBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    threeMonthsBeforeDate.setDate(threeMonthsBeforeDate.getDate() - 93)
                    return threeMonthsBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "4") {
                    const sixMonthsBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    sixMonthsBeforeDate.setDate(sixMonthsBeforeDate.getDate() - 186)
                    return sixMonthsBeforeDate.toISOString().split('T')[0]
                } else if (this.selectedTime == "5") {
                    const yearBeforeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    yearBeforeDate.setDate(yearBeforeDate.getDate() - 365)
                    return yearBeforeDate.toISOString().split('T')[0]
                } else {
                    const allTimeDate = (new Date((new Date()).getTime() - (offset * 60 * 1000)))
                    allTimeDate.setDate(allTimeDate.getDate() - 10000)
                    return allTimeDate.toISOString().split('T')[0]
                }
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            await this.loadData()
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 32px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }

    .time-select {
        width: 12rem;
        margin-bottom: 2rem;
    }

    .chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
    }

    @media only screen and (min-width: 70rem) {
        .chart-container {
            height: 20rem;
        }
    }

    .sum {
        color: var(--color-orange);
        padding-bottom: 16px;
        font-size: 16px;
    }
</style>
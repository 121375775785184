<template>
    <div class="card-dark">
        <div v-if="profile" class="profile-body">
            <div class="title">
                <span>
                    {{ profile.name }}
                </span>
                <div class="button-container button-container-one">
                    <button v-if="isLoggedUsersProfile" class="btn btn-secondary color-white" @click="settings()" aria-label="settings" title="Settings">
                        <fai :icon="['fas', 'gear']"></fai>
                    </button>
                    <button v-if="isAdmin" class="btn btn-secondary color-white" @click="ban()" aria-label="ban" title="Ban">
                        <fai :icon="['fas', 'ban']"></fai>
                    </button>
                    <button v-if="isLoggedUsersProfile" class="btn btn-secondary color-white" @click="upload()" aria-label="upload" title="Upload">
                        <fai :icon="['fas', 'upload']"></fai>
                    </button>
                    <button v-if="isAdmin" class="btn btn-secondary color-white" @click="statistics()" aria-label="analytics" title="Analytics">
                        <fai :icon="['fas', 'chart-simple']"></fai>
                    </button>
                    <button v-if="!isLoggedUsersProfile" :class="['follow-button', 'btn', 'btn-primary', isFollowing ? 'following' : '']" @click="isFollowing ? unfollow() : follow()">
                        <span v-if="!isFollowing">Follow</span>
                        <span v-else>Following</span>
                    </button>
                </div>
            </div>            
            <div class="follower-socials">
                <div class="follower-count">
                    <img src="/icons/people.svg" />
                    &nbsp;{{ profile.followerCount }}
                    &nbsp;Followers
                </div>
                <div v-if="hasAnySocial" class="socials">
                    <a v-if="profile.socials.reddit" :href="'https://reddit.com/user/' + profile.socials.reddit" target="_blank"><img src="/icons/reddit.svg" title="Reddit" alt="reddit icon"/></a>
                    <a v-if="profile.socials.throne" :href="'https://throne.com/' + profile.socials.throne" target="_blank"><img src="/icons/throne.svg" title="Throne" alt="throne icon"/></a>
                    <a v-if="profile.socials.youtube" :href="'https://youtube.com/' + profile.socials.youtube" target="_blank"><img src="/icons/youtube.svg" title="Youtube" alt="youtube icon"/></a>
                    <a v-if="profile.socials.instagram" :href="'https://instagram.com/' + profile.socials.instagram" target="_blank"><img src="/icons/instagram.svg" title="Instagram" alt="instagram icon"/></a>
                    <a v-if="profile.socials.tiktok" :href="'https://tiktok.com/' + profile.socials.tiktok" target="_blank"><img src="/icons/tiktok.svg" title="Tiktok" alt="tiktok icon"/></a>
                    <a v-if="profile.socials.twitter" :href="'https://twitter.com/' + profile.socials.twitter" target="_blank"><img src="/icons/twitter.svg" title="Twitter" alt="twitter icon"/></a>
                    <a v-if="profile.socials.patreon" :href="'https://patreon.com/' + profile.socials.patreon" target="_blank"><img src="/icons/patreon.svg" title="Patreon" alt="patreon icon"/></a>
                    <a v-if="profile.socials.discord" :href="'https://discord.com/invite/' + profile.socials.discord" target="_blank"><img src="/icons/discord.svg" title="Discord" alt="discord icon"/></a>
                </div>
            </div>
            <p class="description">
                {{ profile.description }}
            </p>
            <div class="button-container button-container-two">
                <button v-if="isLoggedUsersProfile" class="btn btn-secondary" @click="settings()" aria-label="settings">
                    <fai :icon="['fas', 'gear']"></fai>
                </button>
                <button v-if="isAdmin" class="btn btn-secondary" @click="ban()" aria-label="ban">
                    <fai :icon="['fas', 'ban']"></fai>
                </button>
                <button v-if="isLoggedUsersProfile" class="btn btn-secondary" @click="upload()" aria-label="upload">
                    <fai :icon="['fas', 'upload']"></fai>
                </button>
                <button v-if="isAdmin" class="btn btn-secondary" @click="statistics()" aria-label="ban">
                    <fai :icon="['fas', 'chart-simple']"></fai>
                </button>
                <button v-if="!isLoggedUsersProfile" :class="['follow-button', 'btn', 'btn-primary', isFollowing ? 'following' : '']" @click="isFollowing ? unfollow() : follow()">
                    <span v-if="!isFollowing">Follow</span>
                    <span v-else>Following</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import Swal from 'sweetalert2'

    export default {
        name: 'ProfileCard',
        components: {
        },
        props: {
            profile: {
                default: null,
                type: Object
            }
        },
        data() {
            return {
                moreOptionsOpen: false,
                isFollowing: false,
                waitingForFollowerResponse: false
            }
        },
        computed: {
            user() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user
                }

                return null
            },
            isLoggedUsersProfile() {
                if (this.$store.getters.userData == null) {
                    return false
                }

                return this.profile != null && this.$store.getters.userData.userProfileId == this.profile.id
            },
            follows() {
                if (this.$store.getters.follows) {
                    return this.$store.getters.follows
                }

                return null
            },
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            },
            hasAnySocial() {
                return this.profile.socials.reddit ||
                    this.profile.socials.throne ||
                    this.profile.socials.youtube ||
                    this.profile.socials.instagram ||
                    this.profile.socials.tiktok ||
                    this.profile.socials.twitter ||
                    this.profile.socials.patreon ||
                    this.profile.socials.discord
            }
        },
        methods: {
            async follow() {
                if (this.user == null) {
                    Swal.fire({
                        position: 'center',
                        title: 'Follow',
                        text: 'You need to have an account to do this.',
                        confirmButtonText: 'Log In',
                        denyButtonText: 'Register',
                        showDenyButton: true,
                        cancelButtonText: 'Maybe later',
                        showCancelButton: true,
                        background: "#262639",
                        color: "white",
                        customClass: {
                            confirmButton: 'dialog-btn-primary',
                            denyButton: 'dialog-btn-primary',
                            cancelButton: 'dialog-btn-secondary'
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push('/login')
                        } else if (result.isDenied) {
                            this.$router.push('/register')
                        }
                    })
                    return
                }

                if (!this.waitingForFollowerResponse && !this.isLoggedUsersProfile) {
                    this.waitingForFollowerResponse = true
                    this.isFollowing = true
                    this.profile.followerCount += 1

                    const followResult = await requests.UserContent.UserProfiles.FollowProfile(this.profile.id)
                    if (!followResult.isSuccess) {
                        this.isFollowing = false
                        this.profile.followerCount -= 1
                    }
                    this.waitingForFollowerResponse = false
                }
            },
            async unfollow() {
                if (!this.waitingForFollowerResponse && !this.isLoggedUsersProfile) {
                    this.waitingForFollowerResponse = true
                    this.isFollowing = false
                    this.profile.followerCount -= 1

                    const followResult = await requests.UserContent.UserProfiles.UnfollowProfile(this.profile.id)
                    if (followResult.isSuccess) {
                        this.isFollowing = true
                        this.profile.followerCount += 1
                    }
                    this.waitingForFollowerResponse = false
                }
            },
            statistics() {
                this.$router.push('/analytics/user/' + this.profile.userId)
            },
            upload() {
                this.$router.push('/upload')
            },
            settings() {
                this.$router.push('/settings')
            },
            ban() {
                Swal.fire({
                    title: 'Ban ' + this.profile.name,
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Ban',
                    showLoaderOnConfirm: true,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                    preConfirm: async (text) => {
                        if (text.length <= 0) {
                            Swal.showValidationMessage(`Provide a reason for baning this user.`)
                            return
                        }

                        const response = await requests.UserAccess.Users.BanUserById(this.user.id, text)

                        if (response.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white",
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something went wrong!',
                                background: "#262639",
                                color: "white",
                            })
                        }
                    }
                })
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            if (this.user) {
                const isFollowingResponse = await requests.UserContent.Follow.IsFollowingProfile(this.$store.getters.userData.userProfileId, this.profile.id)
                if (isFollowingResponse.isSuccess) {
                    this.isFollowing = isFollowingResponse.isFollowing
                }
            }
        }
    }
</script>

<style scoped>  
    .title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        font-size: 28px;
        font-weight: 600;
        line-height: 42px;
        color: var(--primary-color)
    }

    .description {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        color: #ADADCB;
        padding-top: 16px;
        white-space: pre-wrap;
    }

    .follow-button {
        width: 130px;
        border-radius: 12px;
        padding: 12px 24px 12px 24px;
    }
        .title .follow-button {
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
        }

    .follow-button.following {
        color: var(--primary-color) !important;
        background-color: #A688FA33 !important;
        border: 1px var(--primary-color) solid !important;
    }

    .follow-button.following:hover {
        transform: translateX(6px)
    }

    .follow-button.following:hover {
        letter-spacing: 0;
    }

    .follower-count {
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }

    .profile-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .socials {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
    }

        .socials img {
            width: 24px;
            cursor: pointer;
        }   
        
    .button-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }

    .button-container-one {
        display: none;
    }

    .button-container-two {
        display: flex;
    }

    .button-container-two * {
        flex-grow: 1;
    }

    @media only screen and (min-width: 40rem) {
        .button-container-one {
            display: flex;
        }

        .button-container-two {
            display: none;
        }
    }

    .follower-socials {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    @media only screen and (min-width: 55rem) {
        .follower-socials {
            flex-direction: row;
        }
    }
</style>
<template>
    <div class="card card-dark" @click="redirect()" role="group" :aria-label="audio ? 'Audio' : 'Profile'">
        <div class="container" :class="[showDescription ? 'higher' : '']">
            <div class="container-content">
                <div v-if="audio" :title="audio.name">
                    <div>
                        <img src="/icons/microphone.svg" alt="microphone icon" />
                        <span class="audio-profile-name">{{ audio.profileName }}</span>
                    </div>

                    <h5 class="title rows-2" aria-label="Audio name">
                        <span role="link">{{ audio.name }}</span>
                    </h5>
                    
                    <span v-if="showDescription" class="rows-2 mt-1">
                        {{ audio.description }}
                    </span>
                    <TagDisplayContainer v-if="tagsToDisplay"
                                         :tags="tagsToDisplay"
                                         :statusesByTagIds="statusesByTagIds"
                                         class="mt-2"></TagDisplayContainer>
                </div>
                <div v-else :title="profile.name">
                    <img src="/icons/profile-circle.svg" alt="profile icon"/>
                    <h5 class="title rows-2" role="link">
                        {{ profile.name }}
                    </h5>
                    <span class="rows-2 mt-1">
                        {{ profile.description }}
                    </span>
                </div>
            </div>
            <div class="container-footer">
                <div v-if="audio">
                    <ul>
                        <li>
                            <img src="/icons/music.svg" alt="music icon" />
                            {{ audio.listenCount }} Listens
                        </li>
                        <li>
                            <img src="/icons/calendar.svg" alt="calendar icon" />
                            {{ uploaded }}
                        </li>
                        <li>
                            <img src="/icons/waveform.svg" width="24" height="24" alt="waveform icon" />
                            {{ timeLabel }}
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <ul>
                        <li>
                            <img src="/icons/people.svg" alt="people icon"/>
                            {{ profile.followerCount }} Followers
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { dateToTimeAgoString, secondsToTimeLabel } from './../utils.js'
    import TagDisplayContainer from './TagDisplayContainer.vue'

    export default {
        name: 'SearchContentCard',
        components: {
            TagDisplayContainer
        },
        props: {
            profile: {
                default: null,
                type: Object
            },
            audio: {
                default: null,
                type: Object
            },
            showDescription: {
                default: false,
                type: Boolean
            },
            statusesByTagIds: {
                default: {},
                type: Object
            },
        },
        computed: {
            uploaded() {
                return dateToTimeAgoString(this.audio.created, true)
            },
            timeLabel() {
                return secondsToTimeLabel(this.audio.lengthSeconds - 1)
            },
            isNewAudio() {
                if (this.audio) {
                    let created = new Date(this.audio.created)
                    let now = new Date()
                    
                    let diff = now - created

                    let diffDays = diff / 1000 / 60 / 60 / 24
                    return diffDays < 3
                }

                return false
            }
        },
        data() {
            return {
                tagsToDisplay: null
            }
        },
        methods: {
            redirect() {
                if (!this.audio) {
                    this.$router.push({ name: 'Profile', params: { handle: this.profile.handleName } })
                } else {
                    this.$router.push({ name: 'Audio', params: { id: this.audio.id } })
                }
            },
            redirectToProfile() {
                this.$router.push({ name: 'Profile', params: { handle: this.profile.handleName } })
            }
        },
        mounted() {
            if (this.audio) {
                this.tagsToDisplay = []

                if (this.audio.genderPreferences) {
                    for (const genderPreference of this.audio.genderPreferences) {
                        this.tagsToDisplay.push(genderPreference)
                    }
                }

                if (this.audio.categories) {
                    for (const category of this.audio.categories) {
                        this.tagsToDisplay.push(category)
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .card {
        cursor: pointer;
        min-height: 350px;
        display: flex;
    }

    .container {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        gap: 1rem;
    }

    .audio-length {
        font-size: 16px;
    }

    .audio-profile-name {
        color: white;
        letter-spacing: 1px;
    }

    .container-content {
        display: flex;
        flex-flow: row;
    }

        .profile-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rows-2 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rows-1 {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .btn-secondary {
            min-width: 2.2rem;
        }

        .tag {
            font-size: 0.8rem;
            border: 1px solid gray;
            color: gray;
            border-radius: 5px;
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 0.5rem;
        }

        .title {
            padding-top: 16px;
            padding-bottom: 16px;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: var(--primary-color);
            max-height: 72px;
        }

        .container-footer {
        }

            .container-footer ul {
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                list-style-type: none;
                gap: 24px;
            }

                .container-footer ul li {
                    color: #FFFFFF;
                    font-size: 16px;
                }

        @media screen and (max-width: 40rem) {
            .container-footer ul {
                flex-direction: column;
            }
        }
</style>
<template>
    <div class="col-12 col-lg-10 offset-lg-1">
        <div class="card card-dark">
            <div class="card-body">
                <div>
                    <h5>
                        Audio Love rewards program
                    </h5>
                </div>
                <div>
                    <ul>
                        <li class="color-pink">
                            For every 1000 listens you will be rewarded 2$.
                        </li>
                        <li>
                            Payout will be handled via Paypal.
                        </li>
                        <li>
                            Use of any bots is not allowed. Anyone caugth using bots will be disqualified. 
                        </li>
                        <li>
                            We have the right to change the rules.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setMetadata } from '../utils.js'

export default {
    name: 'RewardsPage',
    data() {
        return {
                               
        }
    },
    methods: {
            
    },
    mounted() {
        setMetadata("Rewards | AudioLove", "We reward our creators!")
    }
}
</script>

<style scoped>
    
</style>